let default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
let resize_width = window.innerWidth;
const break_point = 767;
jQuery(function() {
  let default_height = jQuery(document).innerHeight();//アクセスした時の高さを取得

  if (default_width > break_point) {
    let headerHeight = jQuery(".l-header").height();
    jQuery(".c-title-page").css("padding-top",headerHeight);
  }
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  jQuery(".widelink").on('click',function() {
    let url = jQuery(this).find("a").attr("href");
    if (jQuery(this).find("a").attr("target") == '_blank') {
      window.open(url, '_blank');
      return false;
    } else {
      window.location = url;
      return false;
    }
  });

  jQuery(".widelink--sp").on('click',function(){
    if (default_width <= break_point) {
      window.location=jQuery(this).find("a").attr("href");
      return false;
    }
  });

  //スムーススクロール（ページ内リンク）
  jQuery('a[href^="#"]').on("click", function () {
    const f = 600;
    e = jQuery(this).attr("href"),
    g = jQuery(e == "#" || e == "" ? "html" : e),
    h = jQuery(".l-header").height(),
    d = g.offset().top - h;
    jQuery("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });
  jQuery('.js-scroll').on("click", function () {
    const f = 600;
    e = jQuery(this).attr("href"),
    g = jQuery(e == "#" || e == "" ? "html" : e),
    h = jQuery(".l-header").height(),
    d = g.offset().top - h;
    jQuery("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  jQuery('.js-tab-menu .js-tab-menu__item').on('click',function() {
    let i = jQuery(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    jQuery(this).siblings().removeClass('is-active');
    // クリックされたタブにクリック済みデザインを適用する
    jQuery(this).addClass('is-active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    jQuery(this).parents(".js-tab-menu").next(".js-tab-target").children(".js-tab-target__item").hide().removeClass("is-show").eq(i).fadeIn(300).addClass("is-show");
  });

  //accordion
  jQuery(".js-accordion .js-accordion__btn").on('click',function() {
    jQuery(this).toggleClass("open");
    jQuery(this).next(".js-accordion__item").slideToggle();
  });

  //pulldown
  jQuery(".js-pulldown .js-pullDownList").on('click',function() {
    jQuery(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //追従バナー
  const fixB = jQuery(".fixBnr");
  if (fixB.length) {
    jQuery(window).on('scroll',function () {
      let doch = jQuery(document).innerHeight(), //ページ全体の高さ
      winh = jQuery(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = jQuery(".footer").innerHeight();
      if (jQuery(this).scrollTop() > 100 && jQuery(this).scrollTop() < bottom - footh) {
        fixB.addClass("show");
      } else {
        fixB.removeClass("show");
      }
    });
  }

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  let getDevice = (function() {
  let ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------*/
      /* PCの時
      /*-------------------------------------*/
      jQuery(".widelink").hover(
        function(e) {
            jQuery(this).addClass("hover");
        },
        function(e) {
            jQuery(this).removeClass("hover");
        }
      );
    }
  })();

  /*------------------------*/
  //バーガーメニュー表示・非表示
  /*------------------------*/
  const burgerBtn = jQuery('.l-header__burger-button');
  let headerHeight = jQuery('.l-header').innerHeight();
  let headerInnerHeight = jQuery('.l-header').height();
  // スクロールがヘッダーの高さに達したらボタン表示
  jQuery(window).scroll(function () {
    if (jQuery(this).scrollTop() > headerInnerHeight) {
      jQuery(".l-header").addClass("js-fixed-nav");
    } else if (jQuery(this).scrollTop() < headerInnerHeight) {
      if (jQuery(".l-header").hasClass("js-fixed-nav")) {
        jQuery(".l-header").removeClass("js-fixed-nav");
      }
    } else {
      if (jQuery(".l-header").hasClass("js-fixed-nav")) {
        jQuery(".l-header").removeClass("js-fixed-nav");
      }
    }
  });
  //バーガーボタンクリック
  burgerBtn.on('click',function() {
    default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
    if (default_width <= 768) {
      burgerBtn.toggleClass("is-open");
      if (jQuery(".l-header__nav").hasClass("is-show")) {
        jQuery(".l-header__nav").removeClass("is-show").addClass("is-hide");
      } else if (jQuery(".l-header__nav").hasClass("is-hide")) {
        jQuery(".l-header__nav").removeClass("is-hide").addClass("is-show");
      } else {
        jQuery(".l-header__nav").addClass("is-show");
      }
      if (jQuery(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        jQuery(".l-header__cover").removeClass("is-hide").addClass("is-show");
      } else {
        jQuery(".l-header__cover").addClass("is-hide").removeClass("is-show");
      }
    }
  });
  //カバーをクリック
  jQuery(".l-header__cover,.l-header__nav a").on('click',function() {
    if (default_width <= 768) {
      jQuery(".l-header__nav").removeClass("show").addClass("hide");
      burgerBtn.removeClass("is-open");
      jQuery(".l-header__cover").addClass("hide").removeClass("show");
    }
  });
  //バーガー内トグルをクリック
  jQuery(".l-header__menu--trigger.js-toggle").children(".l-header__menu").show();
  jQuery(".l-header__menu--trigger").on('click',function() {
    if (default_width <= 768) {
      jQuery(this).children(".l-header__menu").slideToggle(300);
      jQuery(this).toggleClass("js-toggle");
    }
  });
  
  /*------------------------*/
  //リサイズ時の処理（リサイズした瞬間に走る）
  /*------------------------*/
  jQuery(window).on('resize',function() {
    if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
      return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
    } else {
      default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
      resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
      if (resize_width <= break_point) {
        /*------------------------*/
        //スマホ処理（リサイズ時）
        /*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        jQuery(".l-header__nav").removeClass("is-show is-hide");
        burgerBtn.removeClass("is-open");
        jQuery(".l-header__cover").addClass("is-hide").removeClass("show");
        jQuery(".l-header__menu--trigger").removeClass("js-toggle");
        jQuery(".l-header__menu--trigger").children(".l-header__menu").hide();
      } else {
        /*------------------------*/
        //PC処理（リサイズ時）
        /*------------------------*/
        jQuery(".l-header__nav").removeClass("is-show is-hide");
        jQuery(".l-header__menu--trigger").removeClass("js-toggle");
        jQuery(".l-header__menu--trigger").children(".l-header__menu").show();
      }
    }
  });

  /*------------------------*/
  //リサイズ完了時点処理（完了するまで処理されない）
  /*------------------------*/
  let timer = false;
  jQuery(window).on('resize',function() {
    if (timer !== false) {
        clearTimeout(timer);
    }
    timer = setTimeout(function() {
      resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
      if (resize_width <= break_point) {
        /*------------------------*/
        //スマホ処理（リサイズ完了時）
        /*------------------------*/
        // jQueryheader.removeClass(scrollClass);
        jQuery(".c-title-page").css("padding-top", "inherit");
      } else {
        /*------------------------*/
        //PC処理（リサイズ完了時）
        /*------------------------*/
        let headerHeight = jQuery(".l-header").height();
        jQuery(".c-title-page").css("padding-top", headerHeight);
      }
      docH = jQuery(document).height(),
      winH = jQuery(window).height()
      footeH = jQuery('.footer').outerHeight(),
      topBtnPos = docH - winH - footeH;
    }, 200);
  });
  if (jQuery(".p-home-mv__slider").length) {
    const swiper = new Swiper('.p-home-mv__slider', {
      loop: true,//ループ
      speed: 3000,//スライドスピード
      effect: 'fade',//フェードエフェクト適用
      slidesPerView: 1,
      allowTouchMove: false,
      autoplay: {//自動再生
        delay: 3000,
        disableOnInteraction: false,
        stopOnLastSlide: false
      },
    })
  }

  if (jQuery(".p-field-voice__slider .p-field-voice__slider--wrap").length) {
    const mySwiper = new Swiper('.p-field-voice__slider .p-field-voice__slider--wrap', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      grabCursor: true,
      navigation: {
        nextEl: '.p-field-voice__slider .swiper-button-next',
        prevEl: '.p-field-voice__slider .swiper-button-prev',
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
        }
      },
    });
  }

  if (jQuery(".p-brand-kv__slider .p-brand-kv__slider--wrap").length) {
    const mySwiper = new Swiper('.p-brand-kv__slider .p-brand-kv__slider--wrap', {
      loop: true,//ループ
      speed: 2000,//スライドスピード
      effect: 'fade',//フェードエフェクト適用
      slidesPerView: 1,
      autoplay: {//自動再生
        delay: 3000,
        disableOnInteraction: false,
        stopOnLastSlide: false
      },
      pagination: {
        el: '.p-brand-kv__slider .swiper-pagination',
        clickable: true,
      },
    });
  }

  //mp4の場合
  jQuery('.js-modal-mp4').modalVideo();
  //YouTubeの場合
  jQuery('.js-video-play').modalVideo({
    channel: 'youtube',
    youtube: {
      autoplay: 1,
      mute: 0,
    },
  });
  //Vimeoの場合
  // jQuery('.p-voice-article__media--video').modalVideo({
  //   channel: 'vimeo',
  //   vimeo: {
  //       autoplay: true,
  //       muted: true
  //   }
  // });
});

if (jQuery(".home").length) { //トップページのみでアニメーション実装
  const body = ('.home') ,
        floatingBtn = ('.c-button-floating--md'),
        mv = ('.js-animation-mv'),
        logo = ('.js-animation-mv-logo'),
        image = ('.js-animation-mv-image'),
        mvText = ('.js-animation-mv-text');

  const tl = gsap.timeline();
  tl.fromTo(body, .1,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      ease: 'power2.inOut'
    },
  )
  .fromTo(mv, 1,
    {
      opacity: 0,
      x: '-10%',
    },
    {
      opacity: 1,
      x: '0%',
      ease: 'power2.inOut'
    },
  )
  .fromTo(image, 1,
    {
      opacity: 0,
      y: '15%',
    },
    {
      opacity: 1,
      y: '0',
      ease: 'power2.inOut'
    },
    '-=0.5'
  )
  .fromTo(logo, 1,
    {
      opacity: 0,
      x: '-10%',
    },
    {
      opacity: 1,
      x: '0%',
      ease: 'expo.out'
    },
  )
  .fromTo(mvText, 1,
    {
      opacity: 0,
      x: '-10%',
    },
    {
      opacity: 1,
      x: '0%',
      ease: 'expo.out'
    },
    '-=0.5'
  )
  .fromTo(floatingBtn, 1,
    {
      opacity: 0,
    },
    {
      opacity: 1
    },
    '-=1'
  )

  const fadeInTarget = gsap.utils.toArray(".js-animation-fade-in");
  fadeInTarget.forEach(fadeInTarget => {
    gsap.fromTo(fadeInTarget, {
      opacity: 0, // アニメーション開始前は透明
      }, {
        opacity: 1, // アニメーション後は出現(透過率0)
        scrollTrigger: {
          toggleActions: "play none none reverse", // 上スクロールで戻る
          trigger: fadeInTarget, // アニメーションが始まるトリガーとなる要素
          start: "top center", // アニメーションの開始位置
        },
      }
    );
  });

  gsap.fromTo(".js-animation-continuous", {
    y: 50, // アニメーション開始前の縦位置(下に100px)
    opacity: 0, // アニメーション開始前は透明
    }, {
      y: 0, // アニメーション後の縦位置(上に100px)
      opacity: 1, // アニメーション後は出現(透過率0)
      stagger: 0.3, // 0.2秒遅れて順番に再生
      scrollTrigger: {
        trigger: ".js-animation-continuous", // アニメーションが始まるトリガーとなる要素
        start: "top center", // アニメーションの開始位置
      },
    }
  );

  gsap.fromTo(".js-animation-continuous-2", {
    y: 50, // アニメーション開始前の縦位置(下に100px)
    opacity: 0, // アニメーション開始前は透明
    }, {
      y: 0, // アニメーション後の縦位置(上に100px)
      opacity: 1, // アニメーション後は出現(透過率0)
      stagger: 0.3, // 0.2秒遅れて順番に再生
      scrollTrigger: {
        trigger: ".js-animation-continuous-2", // アニメーションが始まるトリガーとなる要素
        start: "top bottom", // アニメーションの開始位置
      },
    }
  );
}